@tailwind base;
@tailwind components;
@tailwind utilities;

/*Variables*/
:root {
    --site-width: 100%;
    --site-container-width: 1800px;
    --primary-font: 'Maven Pro', sans-serif;
    --heading-font: 'Maven Pro', sans-serif;
    --primary-color: #222;
    --heading-color: #000;
    --link-hover: #0018C9;
    --header-color: #343d43;
    --gray-text: #4c4c4c;
}

/*General*/
* {
    box-sizing: border-box;
  }
  
html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--primary-font);
    font-size: 14px;
    line-height: 1.7 !important;
    font-weight: 400;
    color: var(--primary-color);
    background: #fff;
    height: 100vh;
}

a {
    transition: all .3s linear;
}

/*Scrollbar*/
.scrollbar {
    &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
        background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#4D9C41), to(#19911D), color-stop(.6,#54DE5D))
    }
}
/*Button*/
button {
    transition: all .3s linear;
}

.btn {
    color: #fff;
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 22px;
    border-width: 1px;
    margin-left: 10px;
    padding: 12px 25px;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    border: none;
    position: relative;
    background: transparent;
    z-index: 9;
    text-transform: capitalize;
    border-radius: 8px;
    &:hover {
        color: #fff;
    }
    &::before {
        content: "";
        opacity: 1;
        position: absolute;
        bottom: -7px;
        right: -4px;
        width: 96%;
        height: 93%;
        padding: 0 5px;
        z-index: -2;
        -webkit-transform: skew(-10deg);
        -moz-transform: skew(10deg);
        -o-transform: skew(10deg);
        background: transparent;
        transition: all ease-in 0.25s;
        outline: 4px solid #293341;
        border-radius: 8px;
    }
    &::after {
        content: "";
        opacity: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0 5px;
        z-index: -1;
        -webkit-transform: skew(-10deg);
        -moz-transform: skew(10deg);
        -o-transform: skew(10deg);
        background: #293341;
        transition: all ease-in 0.25s;
        border-radius: 8px;
    }
    &:hover::before {
        right: 4px;
        bottom: 4px;
        width: 95%;
        height: 85%;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font);
    font-weight: 700;
    color: #293341;
    line-height: 1.2;
}

a:hover {
    color: var(--link-hover);
}

.center {
    text-align: center;
}

.relative {
    position: relative;
}

.site-container {
    max-width: var(--site-container-width);
    margin: 0 auto;
    padding: 0 5rem;
}

.section-title {
    &.flex-title {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 20px;
        align-items: center;
    }
    .title {
        font-size: 35px;
        font-weight: 500;
        color: #343D43;
    }
    .view-all {
        text-align: right;
    }
}

/*Breadcrumbs*/ 
.page-titlebar {
    margin-bottom: 20px;
    .entry-title {
        font-size: 42px;
        color: #293341;
    }
}
.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 20px;
    li {
        font-size: 16px;
        font-weight: 500;
        color: #595E5F;
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            height: 3px;
            width: 3px;
            background: #8f8f8f;
            border-radius: 20px;
            position: absolute;
            top: 13px;
            left: -10px;
        }
        &:nth-child(1)::before {
            display: none;
        }
        a {
            font-size: 16px;
            font-weight: 500;
            color: #5DD6B9;
            &:hover {
                color: #0274bd;
            }
        }
    }
}
/*Header*/
.header {
    position: sticky;
    padding: 5px 0;
    width: 100%;
    z-index: 98;
    top: 0;
    background-color: #6f45e9;
    .header-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .menu-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
    }
    .vertical-menus {
        .rotate-menus {
            display: flex;
            gap: 40px;
            padding: 0;
            margin: 0;
            text-align: center;
            .nav-item {
                display: inline-block;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    color: #fff;
                    font-size: 16px;
                    &:hover {
                        color: #c7c7c7;
                    }
                }
            }
        }
    }
    .menu-sliding {
        .sliding-icon {
            font-size: 30px;
            svg {
                path {
                    fill: #fff;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: #ffeb3b;
                    }
                }
            }
        }
        .menu-sliding-wrapper {
            transition: 0.4s ease 0.1s;
            position: fixed;
            z-index: 99;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            transition: 1s ease 0.1s;
            transform: translateX(-100%);
            opacity: 0;
            visibility: hidden;
            &.active {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
            }
            .menu-sliding-bar-grid {
                .sliding-bar-menus {
                    background: #293341;
                    position: fixed;
                    width: 320px;
                    height: 100vh;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    padding: 20px 35px;
                    display: flex;
                    align-items: center;
                    .menu-list {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        width: 100%;
                        .menu-item {
                            .menu-link {
                                color: #fff;
                                font-size: 36px;
                                font-weight: 400;
                                display: block;
                                svg {
                                    padding-left: 15px;
                                    height: 18px;
                                    fill: #c91f28;
                                    display: none;
                                    transition: all .3s linear;
                                }
                                &:hover {
                                    color: #c91f28;
                                    svg {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
                .sliding-bar-content {
                    width: calc(100% - 590px);
                    margin-left: 320px;
                    height: 100vh;
                    overflow-x: auto;
                }
                .sliding-bar-sidebar {
                    position: fixed;
                    width: 270px;
                    height: 100vh;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-left: 1px solid #eaeaea;
                    padding: 20px;
                    .close-icon {
                        text-align: right;
                        .sliding-icon {
                            background: #0a0a32;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            color: #fff;
                            &:hover {
                                background: #6f45e9;
                                color: #fff;
                            }
                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-wrapper {
    width: 100%;
    .main-content{
        padding: 30px;
    }
}

/*Page Title*/
.page-title {
    background: #fff;
    padding: 30px 0;
    .entry-title {
        border-color: #121212;
        border-bottom-width: 2px;
        padding-bottom: 10px;
        h1 {
            font-size: 42px !important;
            font-weight: 700 !important;
            line-height: 46px;
            margin-bottom: 10px;
        }
        .date {
            font-size: 13px;
            color: #6c6c6c;
            font-weight: 900;
        }
    }
}

/*Header Current Match*/
.header-current-matches {
    padding: 0;
    background: #0a0a32;
    .swiper {
        // padding: 0 30px;
    }
    .swiper-slide {
        background: #234fcf;
        border-radius: 15px;
        margin: 20px 0;
        &:first-child {
            border-left: 0;
        }
        &:hover {
            transform: rotate(2deg);
            box-shadow: 0 0 25px rgb(0, 0, 0, .10);
            transition: transform 0.3s linear;
        }
    }
    .current-match-link {
        
        .current-matches-item {
            padding: 10px 15px;
            .date {
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
            }
            .subtitle {
                color: rgba(255,255,255,.9);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
            }
            .team-wrap {
                border: 1px dashed #385dc7;
                border-radius: 15px;
                padding: 10px;
                margin: 10px 0;
                .team-name {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    img {
                        height: 24px;
                        width: 24px;
                    }
                    p {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
                .separator {
                    height: 1px;
                    background: #385dc7;
                    width: 80%;
                    margin: 5px auto;
                }
            }
            .current-matches-footer {
                text-align: center;
                color: #fff;
                font-size: 12px;
            }
        }
    }
    .current-match-skeleton {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        .wrap {
            padding: 10px;
            .react-loading-skeleton {
                --base-color: #76af86;
                --highlight-color: #5fa171;
            }
            .teams {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 10px;
            }
        }
    }
}

.discussion-post-slider {
    padding-top: 50px;
    padding-bottom: 50px;
    .section-title {
        text-align: center;
        margin-bottom: 30px;
        .title {
            color: #293341;
            font-size: 35px;
            font-weight: 400 !important;
        }
    }
    .swiper-slide {
        border-radius: 15px;
        border-top: 3px solid #C4C4C4;
        background: linear-gradient(180deg, #F0F0F0 0%, #FFF 100%);
        text-align: center;
        &:hover {
            border-top: 3px solid #5DD6B9;
            box-shadow: 0 0 25px rgb(0 0 0 / 05%);
            .title {
                color: #293341 !important;
            }
            img {
                transform: scale(1.1);
                transition: transform 0.5s ease;
            }
        }
        &:nth-child(1) {
            border-top: 3px solid #5DD6B9;
            background: linear-gradient(180deg, #E2FFF8 0%, #FFF 100%);
            .title {
                color: #293341 !important;
            }
        }
        .discussion-link {
            padding: 50px 40px;
            display: block;
            .discussion-item {
                .dateTime {
                    .date {
                        color: #293341;
                        font-size: 30px;
                        font-weight: 400;
                        letter-spacing: 1.2px;
                    }
                }
                .title {
                    color: #595E5F;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 23px;
                    font-weight: 400 !important;
                    margin-bottom: 20px;
                    min-height: 115px;
                    .badge-status {
                        display: none;
                    }
                }
                .thum {
                    img {
                        border-radius: 10px;
                        transition: transform 0.5s ease;
                    }
                }
            }
        }
    }
}

/*Discussion Page*/
.discussion-grid-wrap {
    border-radius: 15px;
    border-top: 3px solid #C4C4C4;
    background: linear-gradient(180deg, #F0F0F0 0%, #FFF 100%);
    text-align: center;
    &:hover {
        border-top: 3px solid #5DD6B9;
        box-shadow: 0 0 25px rgb(0 0 0 / 05%);
        .title {
            color: #293341 !important;
        }
        img {
            transform: scale(1.1);
            transition: transform 0.5s ease;
        }
    }
    .discussion-link {
        padding: 50px 40px;
        display: block;
        .discussion-item {
            .dateTime {
                .date {
                    color: #293341;
                    font-size: 30px;
                    font-weight: 400;
                    letter-spacing: 1.2px;
                }
            }
            .title {
                color: #595E5F;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                font-weight: 400 !important;
                margin-bottom: 20px;
                min-height: 115px;
                .badge-status {
                    display: none;
                }
            }
            .thum {
                img {
                    border-radius: 10px;
                    transition: transform 0.5s ease;
                }
            }
        }
    }
}

/*Fantasy Tips*/ 
.fantasy-tips-home-list {
    padding: 50px 30px;
}
.fantasy-tips-wrapper {
    .tips-item {
        text-align: center;
        border: 5px solid #e7e9f1;
        position: relative;
        padding-bottom: 50px;
        transition: all .5s linear;
        border-radius: 12px;
        .featured-image-wrapper {
            height: 220px;
            overflow: hidden;
            position: relative;
            border-radius: 7px;
        }
        .tips-featured-image {
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transition: transform 0.5s ease;
        }
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            margin-top: 12px;
            color: #293341;
            padding: 0 15px;
        }
        .date {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -21.5px;
            span {
                background: #293341;
                color: #fff;
                display: inline-block;
                width: 150px;
                padding: 10px;
                font-size: 14px;
                font-weight: 500;
                transition: all .5s linear;
                border-radius: 8px;
            }
        }
        &:hover {
            border-color: #5dd6b9;
            box-shadow: 0 0 25px rgba(0, 0, 0, .40);
            .tips-featured-image {
                transform: scale(1.1);
                transition: transform 0.5s ease;
            }
            .title {
                color: #293341;
                text-decoration: underline;
            }
            .date {
                span {
                    background: #5dd6b9;
                }
            }
        }
    }
}

/*View all link*/
.view-all-link {
    display: flex;
    min-width: 100px;
    align-items: center;
    gap: 8px;
    color: var(--gray-text);
    font-weight: 500;
    justify-content: right;
    svg {
        height: 14px;
        width: 14px;
        path {
            fill: var(--gray-text);
        }
    }
    &:hover {
        color: var(--link-hover);
        svg {
            path {
                fill: var(--link-hover);
            }
        }
    }
}

/*News Page*/
.cricket-news-home-list {
    padding-top: 50px;
    padding-bottom: 50px;
}
.cricket-news-wrapper {
    .news-item {
        position: relative;
        transition: all .5s linear;
        border-radius: 12px;
        .featured-image-wrapper {
            height: auto;
            width: 100%;
            aspect-ratio: 1;
            overflow: hidden;
            position: relative;
            border-radius: 12px 12px 0 0;
            .news-featured-image {
                height: 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                transition: transform 0.5s ease;
                border-radius: 12px 12px 0 0;
            }
            .date {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 45px;
                text-align: center;
                background: rgb(41 51 65 / 90%);
                transition: all 0.5s linear;
                span {
                    writing-mode: vertical-rl;
                    transform: rotate(180deg);
                    height: 100%;
                    color: #fff;
                    font-size: 17px;
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }
        }
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            margin-top: 12px;
            color: #293341;
            padding: 0 15px 15px;
        }
        .hour-ago {
            padding: 0 15px 15px;
            color: #7F8E96;
            svg {
                height: 13px;
                display: inline-block;
                padding-right: 8px;
                fill: #7F8E96;
            }
        }
        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, .10);
            .featured-image-wrapper {
                .news-featured-image {
                    transform: scale(1.1);
                    transition: transform 0.5s ease;
                }
                .date {
                    background: rgb(27 186 147 / 90%);
                    transition: all 0.5s linear;
                }
            }
            .title {
                text-decoration: underline;
            }
        }
    }
}

.match-preview {
    background: #f5f8f9;
}

/*Fantasy Single*/
.page-single-fantasy-tips {
    .tips-main {
        width: 70%;
        margin: 0 auto;
        padding: 30px 0;
        .featured-image {
            margin-bottom: 20px;
            img {
                border-radius: 15px;
                width: 100%;
            }
        }
        .fusion-imageframe {
            display: none;
        }
        .ez-toc-counter {
            display: none;
        }
        .content-box-wrapper {
            background: #fff;
            box-shadow: 0 0 5px 0px #dde5eb;
            padding: 25px 20px;
            margin-bottom: 20px;
            border-radius: 15px;
            .content-box-heading {
                font-size: 22px;
                margin-bottom: 10px;
            }
            .content-container {
                font-size: 18px;
            }
        }
    }
}

.page-single-event {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 20px;
    .single-event-page-title {
        .page-name {
            color: #05057e;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: underline;
        }
        .entry-title {
            border-bottom: 0;
            padding-bottom: 0;
            .title {
                margin-top: 15px;
                margin-bottom: 15px;
                font-weight: 500 !important;
            }
        }
        .post-meta {
            display: flex;
            gap: 35px;
            align-items: center;
            li {
                display: flex;
                align-items: center;
                gap: 5px;
                svg {
                    height: 18px;
                    width: 18px;
                    fill: #5d5dbb;
                }
                span {
                    font-weight: 500;
                    color: #434343;
                    font-size: 15px;
                }
            }
        }
    }
    .event-single-user {
        .swiper-wrapper {
            padding-bottom: 40px;
        }
        .swiper-slide {
            padding: 20px;
            border: 1px solid #eee;
            border-radius: 12px;
            .current-matches-item {
                text-align: center;
                .user-img {
                    height: 100px;
                    width: 100px;
                    display: inline-block;
                    border-radius: 100px;
                    margin-bottom: 10px;
                }
                .name {
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #434343;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .divider {
                    position: relative;
                    margin: 5px 0;
                    height: 1px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 5%;
                        right: 5%;
                        width: 90%;
                        height: 1px;
                        background-image: linear-gradient(to right, transparent, rgb(198 198 198), transparent);
                    }
                }
                .inner-result {
                    h3 {
                        font-size: 15px;
                        color: #434343;
                        font-weight: 500;
                    }
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
        .user-thum-slider {
            .swiper-slide {
                padding: 0;
                border: 0;
                img {
                    height: 40px;
                    width: 40px;
                    border-radius: 100px;
                }
            }
        }
        .team-equation {
            // display: none;
            padding: 20px;
            box-shadow: 0 0 25px rgba(0,0,0,.05);
            border-radius: 12px;
            .match-between {
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                color: #434343;
            }
            .wining-percentage {
                display: flex;
                justify-content: space-between;
                p {
                    font-size: 15px;
                    color: #434343;
                }
            }
        }
    }
}

.page-single-fantasy-tips {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 20px;
    .single-tips-page-title {
        .page-name {
            color: #05057e;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: underline;
        }
        .entry-title {
            border-bottom: 0;
            padding-bottom: 0;
            .title {
                margin-top: 15px;
                margin-bottom: 15px;
                font-weight: 500 !important;
            }
            .subtitle {
                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: #434343;
                    line-height: 1.5;
                    margin-bottom: 25px;
                }
            }
        }
        .post-meta {
            display: flex;
            gap: 35px;
            align-items: center;
            li {
                display: flex;
                align-items: center;
                gap: 5px;
                svg {
                    height: 18px;
                    width: 18px;
                    fill: #5d5dbb;
                }
                span {
                    font-weight: 500;
                    color: #434343;
                    font-size: 15px;
                }
            }
        }
    }
    .featured-image {
        margin-bottom: 20px;
        img {
            width: 100%;
            border-radius: 12px;
        }
    }
    .tips-content {
        margin: 0;
        .fusion-imageframe {
            display: none;
        }
        .content-box-wrapper {
            box-shadow: 0 0 25px rgba(0, 0, 0, .10);
            margin-bottom: 20px;
            padding: 25px;
            border-radius: 12px;
            .content-box-heading {
                font-size: 20px;
                font-weight: 500;
                color: #293341;
                margin-bottom: 10px;
            }
            .content-container {
                font-size: 15px;
                color: #434343;
            }
        }
    }
}

.page-single-news {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 20px;
    .single-news-page-title {
        .page-name {
            color: #05057e;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: underline;
        }
        .entry-title {
            border-bottom: 0;
            padding-bottom: 0;
            .title {
                margin-top: 15px;
                margin-bottom: 15px;
                font-weight: 500 !important;
            }
            .subtitle {
                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: #434343;
                    line-height: 1.5;
                    margin-bottom: 25px;
                }
            }
        }
        .post-meta {
            display: flex;
            gap: 35px;
            align-items: center;
            li {
                display: flex;
                align-items: center;
                gap: 5px;
                svg {
                    height: 18px;
                    width: 18px;
                    fill: #5d5dbb;
                }
                span {
                    font-weight: 500;
                    color: #434343;
                    font-size: 15px;
                }
            }
        }
    }
    .featured-image {
        margin-bottom: 20px;
        img {
            width: 100%;
            border-radius: 12px;
        }
    }
    .news-content {
        margin: 0 10px;
        .fusion-imageframe {
            display: none;
        }
        .content-box-wrapper {
            box-shadow: 0 0 25px rgba(0, 0, 0, .10);
            margin-bottom: 20px;
            padding: 25px;
            border-radius: 12px;
            .content-box-heading {
                font-size: 20px;
                font-weight: 500;
                color: #293341;
                margin-bottom: 10px;
            }
            .content-container {
                font-size: 15px;
                color: #434343;
            }
        }
    }
}

.page-single-discussion {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20px;
    .single-discussion-page-title {
        .title-top-bar {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 20px;
            align-items: center;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 7px;
            .page-name {
                color: #c3c3c3;
                font-size: 20px;
                font-weight: 500;
                &:hover {
                    color: #50A794;
                }
            }
            .social {
                display: flex;
                gap: 5px;
                li {
                    a {
                        svg {
                            height: 24px;
                            width: 24px;
                            fill: #c3c3c3;
                        }
                        &:hover {
                            svg {
                                fill: #50A794;
                            }
                        }
                    }
                }
            }
        }
        .entry-title {
            border-bottom: 0;
            padding-bottom: 0;
            .title {
                margin-top: 15px;
                margin-bottom: 15px;
                font-weight: 500 !important;
                .badge-status {
                    display: none;
                }
            }
            .subtitle {
                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: #434343;
                    line-height: 1.5;
                    margin-bottom: 10px;
                }
            }
        }
        .post-meta {
            display: flex;
            gap: 35px;
            align-items: center;
            li {
                display: flex;
                align-items: center;
                gap: 5px;
                svg {
                    height: 18px;
                    width: 18px;
                    fill: #989898;
                }
                span {
                    font-weight: 500;
                    color: #989898;
                    font-size: 15px;
                }
            }
        }
    }
    .featured-image {
        margin-bottom: 20px;
        img {
            width: 100%;
            border-radius: 12px;
        }
    }
    .fusion-layout-column.fusion_builder_column_inner_1_1 {
        margin: 0 20px 20px;
        .fusion-column-wrapper {
            box-shadow: 0 0 25px rgba(0, 0, 0, .10);
            padding: 25px;
            border-radius: 12px;
            .fusion-title-heading {
                font-size: 20px !important;
                font-weight: 500;
                color: #434343;
            }
        }
    }
    .range-shortcode {
        .ajax-button {
            display: none;
        }
        .range-title {
            text-align: center;
            .title {
                letter-spacing: 2px;
                font-weight: 500;
                font-size: 17px;
                color: #293341;
            }
            .series-name {
                a {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        .range-match-wrapper {
            .swiper-slide {
                text-align: center;
                background: #040d3c;
                border-radius: 12px;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 20px;
                color: #fff;
            }
            .datetime {
                color: #2196F3;
                font-weight: 500;
                font-size: 15px;
            }
            .subtitle {
                font-size: 12px;
                color: #ffffffd9;
                margin-bottom: 15px;
            }
            .team {
                font-size: 16px;
                line-height: 1;
            }
            .range-winner-wrap {
                margin-top: 20px;
                .winner-name {
                    background: #03A9F4;
                    border-radius: 100px;
                    padding: 12px 15px;
                }
                .winner-title {
                    font-size: 15px;
                    font-style: italic;
                    margin-top: 7px;
                }
            }
            .team-divider {
                position: relative;
                margin: 5px 0;
                height: 1px;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 5%;
                    right: 5%;
                    width: 90%;
                    height: 1px;
                    background-image: linear-gradient(to right, transparent, rgb(255,255,255), transparent);
                }
            }
            .range-expert-wrap {
                text-align: center;
                .title {
                    letter-spacing: 2px;
                    font-weight: 500;
                    font-size: 17px;
                    color: #293341;
                }
                .swiper-slide {
                    background: transparent;
                }
            }
        }
        .fusion-button {
            background: rgb(42,207,246);
            background: radial-gradient(circle, rgba(42,207,246,1) 0%, rgba(58,58,58,1) 0%, rgba(20,23,26,1) 100%);
            color: #fff;
            padding: 18px 35px;
            border-radius: 100px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            &:hover {
                background: rgb(42,207,246);
                background: radial-gradient(circle, rgba(42,207,246,1) 0%, rgba(4,0,89,1) 0%, rgba(20,23,26,1) 100%);
            }
        }
    }
    .discussion-content {
        margin: 0;
        .fusion-imageframe {
            display: none;
        }
        .fusion_builder_column_1_2 {
            width: 100% !important;
            margin: 0 !important;
        }
        .fusion-text {
            margin-bottom: 15px;
            font-size: 15px;
            color: #434343;
        }
        .content-box-wrapper {
            box-shadow: 0 0 25px rgba(0, 0, 0, .10);
            margin-bottom: 20px;
            padding: 25px;
            border-radius: 12px;
            .content-box-heading {
                font-size: 20px;
                font-weight: 500;
                color: #293341;
                margin-bottom: 10px;
            }
            .content-container {
                font-size: 15px;
                color: #434343;
                p {
                    margin-bottom: 15px;
                }
                ol {
                    list-style-type: decimal;
                    padding-left: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .event-predictors {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        padding: 25px;
        border-radius: 12px;
        .swiper-wrapper {
            padding-bottom: 40px;
            .swiper-slide {
                border: 1px solid #eaeaea;
                padding: 20px;
                height: auto;
                border-radius: 12px;
                .current-matches-item {
                    text-align: center;
                    .user-img {
                        height: 100px;
                        width: 100px;
                        border-radius: 100px;
                        display: inline-block;
                    }
                    .user-name {
                        .name {
                            font-size: 15px;
                            font-weight: 500;
                            margin: 10px 0;
                        }
                    }
                    .divider {
                        position: relative;
                        margin: 5px 0;
                        height: 1px;
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 5%;
                            right: 5%;
                            width: 90%;
                            height: 1px;
                            background-image: linear-gradient(to right, transparent, #9e9e9e, transparent);
                        }
                    }
                    .inner-result {
                        p {
                            font-size: 15px;
                            span {
                                color: #293341;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
        .user-thum-slider {
            .swiper-wrapper {
                padding-bottom: 0;
                .swiper-slide {
                    border: 0;
                    padding: 0;
                    border-radius: 0;
                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 50px;
                    }
                }
            }
        }
        .match-info {
            text-align: center;
            margin-bottom: 15px;
            .match-between {
                font-size: 18px;
                font-weight: 500;
                line-height: 1;
            }
            .sub {
                color: #989898;
                font-weight: 500;
            }
            .date {
                
            }
        }
    }
}

/* Title */ 
.section-title.title-wrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 16px;
    align-items: center;
    margin-bottom: 30px;
    .title {
        font-size: 28px;
        line-height: 33px;
        color: #343D43;
        margin-bottom: 0;
    }
}

/* Cricket News */ 
.cricket-news {
    .news-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        .news-item {
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgb(0 0 0 / 10%);
            .title {
                font-size: 17px;
                color: #212934;
                line-height: 1.4;
                margin-top: 15px;
            }
            .date {
                text-align: right;
                margin-top: 15px;
                font-size: 12px;
                font-weight: 600;
                color: #8a8a8a;
            }
        }
    }
}

.criclysis-footer {
    padding: 20px;
}

.calendar-tabs {
    .tab-list {
        border-radius: 12px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin-bottom: 20px;
        .tab-item {
            text-align: center;
            cursor: pointer;
            padding: 7px 5px 10px;
            border-bottom: 3px solid transparent;
            color: #8a8a8a;
            font-size: 18px;
            font-weight: 500;
            transition: all .3s linear;
            &:focus {
                outline: none;
            }
            &:hover {
                color: #212934;
            }
            span {
                display: block;
                line-height: 1;
                color: #8a8a8a;
                font-size: 12px;
            }
        }
        .react-tabs__tab--selected {
            color: #212934;
        }
        .today.react-tabs__tab--selected {
            border-color: #339937;
            border-bottom-left-radius: 12px;
        }
        .upcoming.react-tabs__tab--selected {
            border-color: #e9b004;
        }
        .result.react-tabs__tab--selected {
            border-color: #e90404;
            border-bottom-right-radius: 12px;
        }
    }
    .react-tabs__tab-panel {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    }
    .react-tabs__tab-panel--selected {
        opacity: 1;
        visibility: visible;
    }
    .tab-content {
        .item-wrap {
            border-radius: 12px;
            box-shadow: 0 0 25px rgba(0,0,0,.1);
            margin-bottom: 20px;
            .item-header {
                padding: 7px 20px;
                border-bottom: 1px solid #eaeaea;
                h2 {
                    font-size: 16px;
                    margin: 0;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: #595E5F;
                }
            }
            .item-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .single-match-wrap {
                    border-bottom: 1px solid #eaeaea;
                    padding: 10px 20px 20px;
                    &:nth-child(odd) {
                        border-right: 1px solid #eaeaea;
                    }
                    .match-info {
                        font-size: 12px;
                        color: #8a8a8a;
                        font-weight: 400;
                        margin-bottom: 7px;
                    }
                    .team-wrap {
                        margin-bottom: 7px;
                        .teams {
                            display: grid;
                            grid-template-columns: 1fr auto;
                            align-items: center;
                            .team-name {
                                display: grid;
                                grid-template-columns: 20px 1fr;
                                gap: 7px;
                                align-items: center;
                                p {
                                    color: #212934;
                                    font-weight: 500;
                                    font-size: 16px;
                                }
                            }
                            .runs {
                                color: #595E5F;
                                font-weight: 500;
                                font-size: 15px;
                            }
                        }
                    }
                    .result {
                        font-size: 12px;
                        color: #595E5F;
                        font-weight: 500;
                        margin-bottom: 7px;
                    }
                    .match-btns {
                        .match-link {
                            display: inline-block;
                            margin-right: 5px;
                            border: 1px solid #eaeaea;
                            border-radius: 50px;
                            padding: 3px 15px;
                            font-size: 12px;
                            color: #212934;
                            &:last-child {
                                margin-right: 0;
                            }
                            &:hover {
                                color: #50A794;
                                border-color: #50A794;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*Media Query*/ 
@media only screen and (max-width: 1199px) {
    .site-container {
        padding: 0 4rem;
    }
}

@media only screen and (max-width: 991px) {
    .site-container {
        padding: 0 3rem;
    }
}

@media only screen and (max-width: 800px) {
    body {
        height: 100vh;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
            background-color: #F5F5F5;
            border-radius: 10px;
        }
        &::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #FFF;
            background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#4D9C41), to(#19911D), color-stop(.6,#54DE5D))
        }
    }
    .header {
        position: relative;
        width: 100%;
        min-height: auto;
        height: auto;
        flex-direction: initial;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        .vertical-menus {
            display: none;
        }
        .cric-logo {
            height: auto;
            display: block;
            width: auto;
            border-bottom: 0;
        }
        .menu-sliding {
            height: auto;
            display: block;
            text-align: right;
            border-top: 0;
        }
        .menu-sliding {
            .menu-sliding-wrapper {
                background: #293341;
                .sliding-bar-menus {
                    width: 100% !important;
                    position: relative !important;
                    height: auto !important;
                    display: block !important;
                    text-align: left;
                    padding-top: 40px !important;
                }
                .sliding-bar-content {
                    display: none;
                }
                .sliding-bar-sidebar {
                    position: absolute !important;
                    border-left: 0 !important;
                    width: auto !important;
                    height: auto !important;
                    bottom: auto !important;;
                }
            }
        }
    }
    .main-wrapper {
        width: 100%;
        margin-left: 0;
    }
    .main-wrapper.scrollbar {
        &::-webkit-scrollbar-track {
            display: none;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            display: none;
        }
    }
    .page-title {
        .entry-title {
            h1 {
                font-size: 24px !important;
                line-height: 24px;
                .badge-status {
                    font-size: 18px;
                }
            }
        }
    }
    .header {
        .main-navigation {
            .nav-wrap {
                grid-template-columns: 100px 1fr auto;
                grid-gap: 10px;
                .nav {
                    .nav-list {
                        .nav-item {
                            a {
                                padding: 12px 3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .current-match-skeleton {
        overflow: hidden;
    }
    .fantasy-tips {
        .tips-wrap {
            grid-template-columns: 1fr;
            .tips-item {
                .tips-link {
                    img {
                        height: auto;
                    }
                }
            }
        }
    }
    .page-single-fantasy-tips {
        .tips-main {
            width: 100%;
        }
    }
    .page-single-discussion {
        .event-predictors {
            .swiper-pagination {
                display: none;
            }
        }
    }
    .calendar-tabs {
        .tab-list {
            .tab-item {
                font-size: 15px;
                span {
                    font-size: 10px;
                }
            }
        }
        .tab-content {
            .item-wrap {
                .item-header {
                    h2 {
                        font-size: 14px;
                    }
                }
                .item-grid {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    .discussion-post-slider,
    .cricket-news-home-list {
        padding: 40px 0;
    }
    .header-current-matches {
        .swiper {
            // padding: 0 15px;
        }
    }
    .section-title {
        .title {
            font-size: 25px;
            font-weight: 700;
        }
    }
    .page-titlebar {
        .entry-title {
            font-size: 25px;
            font-weight: 700;
        }
    }
    .main-wrapper {
        .main-content {
            padding: 20px 15px;
        }
    }
    .page-single-discussion {
        width: 100%;
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .site-container {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 575px) {
    .site-container {
        padding: 0 1rem;
    }
}